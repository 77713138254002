import React, { Component, useState } from "react";
import {isMobile} from 'react-device-detect';
import {
  Container,
  Row,
  Col,
  ProgressBar,
  Form,
  Button,
  Card,
} from "react-bootstrap";
import MyIcon from "./logo.png";
import CompleteImg from "./complete.png";

function Terms() {
  const logo = require("./logo.png");
  
  return (
    <div className="App">
      <div style={{textAlign: "center"}}>
     TERMS AND CONDITIONS FOR CREDIT CARD PAYMENTS AND ELECTRONIC COMMUNICATIONS
SECTION 1 - OVERVIEW

PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS WEBSITE.  SEE IMPORTANT DEFINITIONS IN SECTION 2 BELOW.

This website is operated by Roll-A-Shade Inc.  All users of this website agree that access to and use of this site is subject to the following Terms and Conditions and other applicable law.  If you do not agree to these Terms and Conditions, please do not use this website.  By visiting our website and/or making a credit card payment to us on this website you agree to be bound by the following Terms and Conditions.  These Terms and Conditions apply to all users of the site, including, without limitation, users who are browsers, vendors, customers, merchants, and/or contributors of content.

Any new features or tools that are added to the current website shall also be subject to these Terms and Conditions.  You can review the most current version of the Terms and Conditions at any time on this page.  We reserve the right to update, change or replace any part of these Terms and Conditions at any time by posting updates and/or changes to our website.  It is your responsibility to check this page periodically for changes.  Your continued use of or access to our website following the posting of any changes constitutes acceptance of those changes.

SECTION 2 - DEFINITIONS

As used in these Terms and Conditions:

(a) “Roll-A-Shade”, “we”, “us”, and “our” refer collectively to Roll-A-Shade Inc., and all of its divisions, subsidiaries and affiliates, such as Automated Motorized Shades.
(b) “You” means you, the website browser, vendor, customer, merchant, and/or contributor of content to this website.
(c) “Product” or “Products” means any product provided by us, such as window shades, window coverings, or any other product.
(d) “Service” or “Services” means any service provided by us, such as window shade installation, shade printing, or any other service.
(e) “Electronic Communications” means any electronic billing and payment communications sent by us to you or by you to us, such as online access to statements and emails relating to billing or payment, disclosures, notices and other communications regarding our products and/or services.
(f) “Credit cards” includes both credit cards and debit cards.


SECTION 3 – ONLINE STORE TERMS

By agreeing to these Terms and Conditions, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.

You may not use our products, services, or website for any illegal or unauthorized purpose.  You must not transmit any worms or viruses or any code of a destructive nature.

All users of this site agree that access to and use of this site is subject to the following Terms and Conditions and other applicable law.  If you do not agree to these Terms and Conditions, please do not use this site.

SECTION 4 – GENERAL CONDITIONS

We reserve the right to refuse service to anyone for any reason at any time.

You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.

You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the website, without express written permission by us.

The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms and Conditions.

SECTION 5 – SECURE CARD PROCESSING

All credit card transactions that occur on our website are processed securely by encrypted transmission systems.  When you make your online payment on our website we do not store credit card information, nor do we actually see your credit card information when it is entered by you on your computer.

SECTION 6 – AUTHORIZATION

By entering your credit card information:

(a) You are stating that you are an authorized user of the credit card and that the associated information entered (account holder name, account number, expiry date, CVV2/CVC2 security number, billing address, etc.) is accurate.
(b) You authorize us to charge the amount you have requested to your credit card.
(c) If you set up automatic payments, then you authorize us to charge the amount due for the invoice being paid to the credit card.
(d) You also authorize us to return to your credit card any funds due to you by us resulting from your credit card payments on this site.

SECTION 7 – CHARGES

We may not know the dollar amount of certain extra charges at the time of a prepaid order, including, but not limited to, shipping charges.  These extra charges will be disclosed to you once we know them, and prior to the extra charges being made to your credit card.

For each transaction, in addition to the charge you have authorized, your credit card issuer and network may assess their customary transaction or handling charge, if any.

Foreign payments are defined as a payment made to a merchant located in a different country than the payment method was issued is (e.g., a credit card issued in Canada paying a merchant in the United States).  Foreign payments may be subject to higher fees by the credit card issuer.

If a charge is declined or reversed by the credit card issuer or network, you agree to pay us a service charge and to reimburse us for all reasonable costs of collection.  Your credit card issuer may also assess its customary charge for such transactions.

For fraud prevention, we reserve the right to impose limitations on the size, frequency, and time frame of payments per user.

Contact us as soon as possible at 1-888-245-5077 if you think that the amount of your online payment to us shown on your credit card statement is in error or if you need more information about your payment to us shown on your credit card statement.

By clicking “Submit” you are agreeing not to make a fraudulent dispute of the payment you have authorized with your credit card issuer.  This type of fraud is costly.  As such, to keep our prices low, we may attempt to prevent such fraudulent chargebacks by requiring an authorized signature for acceptance of product deliveries and services, requesting security codes when entering your credit card, and keeping a record of your IP address when a payment is made.

SECTION 8 – DISHONORED REQUESTS FOR PAYMENTS

If your credit card issuer or network does not honor an online payment transaction, then we have the right to charge the amount of any such transaction to your account or to collect the amount from you.

If your credit card issuer or network does not honor an online payment transaction, we may terminate any or all product delivery or service, and we may cancel your right to participate in online payment on our website.

SECTION 9 – CONFIRMATION OF PAYMENT

By clicking “Submit” you are consenting to receive a one-time confirmation of this payment electronically to the email address you have provided to us.

If you set up automatic payments, then you are consenting to receive a one-time confirmation of each payment electronically to the email address you have provided to us.

SECTION 10 – RETURN POLICY

Please note that we do not accept returns without a return authorization number.  You must first obtain a return authorization number from us before returning goods to us to receive a refund or a credit on your account.  You may contact us at 1-888-245-5077 to request authorization for your return.

Returns may be subject to a restocking fee.  Any restocking fees, if applicable, will be disclosed when the return authorization number is issued.

We ask that you carefully examine the shipments you receive, and in the event of any shipping damage to the goods and containing cartons, and please keep the cartons.  Our carriers do insure against damage but they will not entertain a claim without clear evidence.

SECTION 11 – GOODS AND/OR SERVICES WARRANTY

We guarantee all products manufactured in our facilities to be free from defects due to workmanship or materials under normal usage.

We guarantee, to the original purchaser, our manual shades with a limited lifetime warranty, our motors with a 5 year limited warranty, our printed shades with a 3 year warranty against fading and our bead chains for a period of up to 1 year from the date of installation.  

This guarantee does not apply to damage to exterior shades due to high winds from extreme weather conditions, any roller shade or parts that fail due to abuse, neglect, accident, alterations, improper use or installation, theft or gradual color fading due to extreme direct sunlight.

For claims covered by the guarantee, we will replace or repair any product found to be defective as a result of faulty workmanship or materials.  Shipping charges, trip charges and labor costs for measuring and installation and/or removal of old or current products are excluded.  This warranty is available to the original owner and is non-transferable.  

Roll-A-Shade® warrants Eclipse to be free from defects in materials and workmanship for 5 years when used properly and operated under normal conditions from the date of purchase. If Eclipse is found to be defective and covered by the warranty, we will, at our option, repair or replace it free of charge. This product warranty does not cover any conditions or damages resulting from misuse or abuse, accidents, alterations, neglect, damage by others, natural disasters, exposure to the elements, or failure to follow Roll-A-Shade’s instructions with respect to installation, use and maintenance. The repair or replacement of defective product is the purchaser’s sole remedy and the limit of Roll-A-Shade’s obligation. 

EXCEPT AS EXPRESSLY PROVIDED IN THIS PARAGRAPH, ROLL-A-SHADE® MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, NATURE OR DESCRIPTION, EXPRESSED OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY OR FITNESS OF ANY PRODUCTS FOR ANY PARTICULAR PURPOSE, AND HEREBY DISCLAIMS THE SAME. 
In no event shall Roll-A-Shade® be liable or responsible for incidental or consequential damages or for any other direct or indirect damage, loss, cost, expense or fee. 

WARRANTY Exclusions: 
•	Installation and usage are not in accordance with our installation and operating instructions. 
•	Product has been opened, dismantled or returned with clear evidence of abuse or other damage. 
•	Product has been used to perform functions other than the functions it was designed to handle, namely motorizing window shades. 
•	Product is used with electrical accessories (switches, relays, etc.) that have not been previously approved in writing by the Roll-A-Shade®. 
Roll-A-Shade’s warranty is designed to ensure a thoroughly satisfying experience with your window treatment product.

SECTION 12 – LIMITATION OF LIABILITY

We shall not be liable for any special or consequential damages that result from the use of, or the inability to use this site.

We do not guarantee, represent or warrant that your use of our website will be uninterrupted, timely, secure or error-free.

We do not warrant that the results that may be obtained from your use of the website will be accurate or reliable.

You agree that from time to time we may remove products or services from our website for indefinite periods of time or cancel products or services from our website at any time, without notice to you.

You expressly agree that your use of, or inability to use, the website is at your sole risk. 

In no case shall we, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the website, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.

SECTION 13 – ERRORS, OMISSIONS, ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION

We are not responsible if information made available on this site is not accurate, complete or current.  The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information.  Any reliance on the material on this site is at your own risk.  

The information contained or referred to in this website comes from a variety of sources and may not be accurate, error-free, complete, or current. This website is provided as a service to its visitors and the information is provided on an "AS IS" basis and use of such information is at your own risk. 

We make no representations or warranties about the suitability of any information, contained in or referred to in this website, for any purpose. We do not warrant the accuracy, adequacy or completeness of this information and material and expressly disclaims liability for errors or omissions in this information and material. We assume no responsibility for and disclaim all liability for any errors or omissions, warranties and conditions with regard to this website and/or the information contained in or referred to in this website including without limitation all implied warranties and conditions of merchantability and fitness for a particular purpose, title, non-infringement and freedom from computer virus.

This website may contain certain historical information.  Historical information, necessarily, is not current and is provided for reference only.  We reserve the right to modify the contents of this website at any time, but we have no obligation to update any information on our website.  You agree that it is your responsibility to monitor changes to our website.

In the event that one or more of our products are mistakenly listed at an incorrect price, we reserve the right to refuse or cancel any order placed of product listed at the incorrect price.  We reserve the right to refuse or cancel any such order, whether or not the order has been confirmed and your credit card has been charged.  If your credit card has already been charged for the purchase and your order is cancelled, we shall issue a credit to your credit card account in the amount of the incorrect price.

Occasionally there may be information on our website that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the website is inaccurate at any time without prior notice (including after you have submitted your order).

We undertake no obligation to update, amend or clarify information on the website, including without limitation, pricing information, except as required by law. 

SECTION 14 – MODIFICATIONS OF PRICES

Prices for our products are subject to change without notice.  You agree that it is your responsibility to monitor changes to our website.

SECTION 15 – PRODUCTS AND/OR SERVICES

Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.

We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.

We reserve the right, but are not obligated, to limit the sales of our products or services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. 

All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. 

We reserve the right at any time to modify or discontinue a product or service (or any part or content thereof) without notice at any time. 

Any offer for any product or service made on this site is void where prohibited.

SECTION 16 – ACCURACY OF BILLING AND ACCOUNT INFORMATION

We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household, per business or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.

You agree to provide current, complete and accurate purchase and account information for all purchases made on our website. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.

For more detail, please review our Returns Policy.

SECTION 17 – OPTIONAL TOOLS

We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.

You acknowledge and agree that we provide access to such tools “as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.

Any use by you of optional tools offered through the website is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).

We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms and Conditions.

SECTION 18 – THIRD-PARTY LINKS

We may also, in the future, offer new services and/or features through the website (including the release of new tools and resources). Such new features and/or services shall also be subject to these Terms and Conditions.  Certain content, products and services available via our website may include materials from third-parties.

Third-party links on this website may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.

We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.

SECTION 19 – USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS

If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.

We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.

You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.

SECTION 20 – PERSONAL INFORMATION

Your submission of personal information through the store is governed by our Privacy Policy, which can be found at http://www.rollashade.com/privacy.html or http://www.automatedmotorizedshades.com/privacy-policy.html.   We reserve the right to update, change or replace any part of our Privacy Policy.  It is your responsibility to check our Privacy Policy for changes.

SECTION 21 – CONSENT TO ELECTRONIC DISCLOSURES

Because the credit card payment services are provided electronically, you must consent to our providing important information electronically.  We may issue the following information and disclosures electronically on this website or via electronic mail (“email”):

(a) these Terms and Conditions;
(b) any future changes to these Terms and Conditions;
(c) our Privacy Policy;
(d) transaction history information; and
(e) any other notices, legal communications, or disclosures.

To receive this information electronically, and access and retain any disclosures received, you will need a computer with Internet access, and the ability to receive and read email.  Please note that some web browsers may not be supported.

By proceeding with use of our website and online credit card payment services:

(a) you agree to receive information and disclosures electronically on this website and via email and confirm that you will download or print any disclosures for your records;
(b) you acknowledge that you can access information that is provided electronically on this website and via email;
(c) you are authorizing the delivery of disclosures regarding the payment services electronically on this website and via email and providing your consent to receive electronic communications pursuant to the Electronic Signatures in Global and National Commerce Act and intend that this statute apply to the fullest extent possible;
(d) you agree to receive emails regarding the credit card payment service on this website;
(e) you agree to be bound by our Privacy Policy.

You can withdraw your consent to receive most electronic communications from us by emailing us at info@rollashade.com or following the unsubscribe procedure that may be contained in any electronic communication you receive from us.  However, if you withdraw your consent, you may no longer be eligible to use the credit card payment services on this website.  Withdrawing your consent will not affect the validity of pending or completed payments.

SECTION 22 – PROHIBITED USES

In addition to other prohibitions as set forth in the Terms and Conditions, you are prohibited from using the website or its content: 

(a) for any unlawful purpose; 
(b) to solicit others to perform or participate in any unlawful acts; 
(c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; 
(d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; 
(e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; 
(f) to submit false or misleading information; 
(g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the service or of any related website, other websites, or the Internet; 
(h) to collect or track the personal information of others; 
(i) to spam, phish, pharm, pretext, spider, crawl, or scrape; 
(j) for any obscene or immoral purpose; or 
(k) to interfere with or circumvent the security features of the service or any related website, other websites, or the Internet. 

We reserve the right to terminate your use of the service or any related website for violating any of the prohibited uses.

SECTION 23 – INTELLECTUAL PROPERTY

Copyright in the pages and in the screens displaying the pages, and in the information and material therein and in their arrangement, is owned by us, unless otherwise indicated. The trademarks, logos and service marks displayed on this site are our property, unless otherwise indicated. Nothing on this site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any trademark, logo or service mark displayed on this site, without the prior written permission of the mark’s owner. All information and content including any software programs available on the site is protected by copyright. Users of the site are prohibited from modifying, copying, distributing, transmitting, displaying, publishing, selling, licensing, creating derivative works from or otherwise using any information and content on or through the site for commercial, public, or other unauthorized purposes. All other company, product or service names referenced in this web site are used for identification purposes only and may be trademarks of their respective owners.

You may download content of this web site (one machine-readable copy and one print copy per page on a single computer) only for your personal use for non-commercial purposes, provided that the copyright, trademark, or other proprietary notices are not removed from the materials downloaded. No modification or further reproduction of the content is permitted. This is a license and does not transfer any ownership right. The information and materials contained in these pages and the Terms and Conditions set forth herein are subject to change without prior notice. Please visit the site periodically to review the most current terms governing your use of this web site. 

Unauthorized use of our website(s) and systems including but not limited to unauthorized entry into our systems, misuse of passwords, or misuse of any information posted on a site, is strictly prohibited. Not all products and services are available in all geographic areas. Eligibility for particular products and services is subject to final determination by us and/or our affiliates. 

SECTION 24 – NOTICE

We may deliver notice to you by means of email, a general notice on this website, or by another reliable method to the address you have provided to us.

SECTION 25 – APPLICABLE LAWS

These Terms and Conditions and any separate agreements whereby we provide you access to our website shall be governed by and construed in accordance with the laws of the State of California, United States of America.

You may not use any of the information contained in this website in violation of U.S. export laws or regulations or in violation of any applicable local laws or regulations.

This website contains information about products or services that may or may not be available in any particular country, and if applicable, may have received acceptance or market clearance by a governmental regulatory body for different indications and restrictions in different countries. Each country has specific laws, regulations and privacy practices governing the communication of personal or other information about products and services on the Internet. Nothing herein should be construed as a solicitation or promotion for any product or service or for an indication for any product or service that is not authorized by the laws and regulations of the country where the reader resides.

SECTION 26 – SEVERABILITY

In the event that any provision of these Terms and Conditions is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms and Conditions, such determination shall not affect the validity and enforceability of any other remaining provisions.

SECTION 27 – NO WAIVER

Our failure to act with respect to a breach of these Terms and Conditions by you or others does not waive our right to act with respect to subsequent or similar breaches.

SECTION 28 – TERMINATION

The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.

These Terms and Conditions are effective unless and until terminated by either you or us. You may terminate these Terms and Conditions at any time by notifying us that you no longer wish to use our website, or when you cease using our website.

If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms and Conditions, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our website (or any part thereof).

SECTION 29 – ENTIRE AGREEMENT

The failure of us to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision.

These Terms and Conditions and any policies or operating rules posted by us on this website or in respect to our goods and services constitutes the entire agreement and understanding between you and us and govern your use of the website, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms and Conditions).

Any ambiguities in the interpretation of these Terms and Conditions shall not be construed against the drafting party.

SECTION 30 – ADDITIONAL TERMS

Certain sections or pages on this website may contain separate Terms and Conditions, which are in addition to these Terms and Conditions. In the event of a conflict, the additional Terms and Conditions will govern for those sections or pages.

SECTION 31 – CHANGES TO TERMS AND CONDITIONS

You can review the most current version of the Terms and Conditions at any time at this page.

We reserve the right, at our sole discretion, to update, change or replace any part of these Terms and Conditions by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website following the posting of any changes to these Terms and Conditions constitutes acceptance of those changes.

SECTION 32 – CONTACT INFORMATION

Questions about the Terms and Conditions should be sent to us at info@rollashade.com.

      </div>
    </div>
  );
}

export default Terms;
