import Payment from "./Payment.js";
import Complete from "./Payment-Complete.js";
import Terms from "./Payment-Terms";
import "./App.css";
import { isMobile } from "react-device-detect";
import React from "react";
import logo from "./logo-large.png";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {DropdownButton, Dropdown, Button} from "react-bootstrap";

function App() {
  return (
    <div className="App">
      <header className="App-header">

      {isMobile == false && (
        <img style={{ width: 150, margin: 20 }} src={logo} />
      )}
      {isMobile == true && (
        <img style={{ width: 50, margin: 20 }} src={logo} />
      )}

        <Button variant="link" style={{ width: 150, position: "absolute", right: 30}} href="https://www.rollashade.com/contact.html">Contact Roll A Shade</Button>
      {/*
        <DropdownButton style={{ position: "absolute", right: 20 }} id="dropdown-basic-button" title="Contact Roll A Shade" variant="light">
          <Dropdown.Item href="https://www.rollashade.ca/contact.html">Click for Canada</Dropdown.Item>
          <Dropdown.Item href="https://www.rollashade.com/contact.html">Click for USA</Dropdown.Item> 
        </DropdownButton>
      */}
      </header>

      <Router>
        <div>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/complete">
              <Complete />
            </Route>
            <Route path="/terms">
              <Terms />
            </Route>
            <Route path="/">
              <Payment />
            </Route>
          </Switch>
        </div>
      </Router>

      
    </div>
  );
}

export default App;
