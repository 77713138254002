import React, { Component, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import config from "./app_config.json";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
  Container,
  Row,
  Col,
  ProgressBar,
  Form,
  Button,
  Card,
  Table,
  Alert,
  Overlay,
  Tooltip,
  Popover,
  OverlayTrigger
} from "react-bootstrap";
import "./Payment.css";
import MyIcon from "./logo.png";
import axios from "axios";
import e from "cors";
import paymentOptionsImage from "./payment-options.png";
import cvvImage from "./cvv.png";
import { getConfig } from "@testing-library/dom";

function Payment() {
  let history = useHistory();

  const [inputList, setInputList] = useState([
    { type: "proposal", proposalNumber: "", amount: "" },
  ]);

  const onSubmit = (data) => {
    data.address = address;
    setFormData(data);
    advanceStep();
  };
  const { register, handleSubmit, watch, errors } = useForm();

  const [formData, setFormData] = useState(null);
  const [hash, setHash] = useState(null);
  const [key, setKey] = useState(""); 
  const [totalAmount, setTotalAmount] = useState(0);
  const [toggleAutoComplete, setToggleAutoComplete] = useState(true);
  const [orderDesc, setOrderDesc] = useState("");
  const [resCustomer, setResCustomer] = useState(false);
  const [progress, setProgress] = useState(30);
  const [termsChecked, setTermsChecked] = useState(false);
  const [step, setStep] = useState(0);
  const [invalidCaptcha, setInvalidCaptcha] = useState(true);
  const [recaptchaUserToken, setRecaptchaUserToken] = useState("");
  const [stepBtnText, setStepBtnText] = useState("Next Step: Billing Info");
  const [instructions, setInstructions] = useState(
    "Please enter one item per line. For multiple invoices, click “add another”. For assistance with an unknown invoice number or processing a payment, please contact Roll-A-Shade’s accounting department at 888.245.5077 ext. 405."
  );
  const [stepText, setStepText] = useState("Step 1/3: Order Details");
  const [amountError, setAmountError] = useState(false);
  const [totalAmountError, setTotalAmountError] = useState(false);
  const [amountNotSet, setAmountNotSet] = useState(true);
  const [cjsFlag, setCjsFlag] = useState(false);

  const [paymentStatus, setPaymentStatus] = useState({
    status: "",
    message: "",
  });

  const [paymentNumberError, setPaymentNumberError] = useState(false);
  const [paymentNumberNotSet, setPaymentNumberNotSet] = useState(true);

  const [addressNotSet, setAddressNotSet] = useState(false);

  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [DBA, setDBA] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postal, setPostal] = useState("");
  const [country, setCountry] = useState("US");
  const [region, setRegion] = useState("");
  const [currency, setCurrency] = useState("");
  const [paymentFormData, setPaymentFormData] = useState(null);

  // handle input change
  const handleInputChange = (e, index) => {
    var { name, value } = e.target;

    if (name == "proposalNumber") {
      var patt1 = /^\d+$/;

      if (value.length == 0) {
        setPaymentNumberNotSet(true);
        setPaymentNumberError(false);
      } else {
        if (value.match(patt1) == null) {
          setPaymentNumberError(true);
          setPaymentNumberNotSet(true);
        } else {
          setPaymentNumberError(false);
          setPaymentNumberNotSet(false);
        }
      }
    }

    if (name == "amount") {
      var newVal = value.replace(",", "");
      var patt1 = /^\d+(\.[0-9][0-9]?)?$/;

      if (value.length == 0) {
        setAmountNotSet(true);
        setAmountError(false);
      } else {
        if (newVal.match(patt1) == null || newVal == 0) {
          setAmountError(true);
          setAmountNotSet(true);
        } else {
          setAmountError(false);
          setAmountNotSet(false);
        }
      }

      if (value > 50000)
      {
        setTotalAmountError(true);
      }
      else {
        setTotalAmountError(false);
      }




      value = newVal;
    }

    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);

    updateTotalAmount(list);
  };

  const handleSelectInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);

    var emptyAmountFlag = false;
    var emptyPaymentNumberFlag = false;
    for (var i = 0; i < list.length; i++) {
      if (list[i].proposalNumber == "") {
        emptyPaymentNumberFlag = true;
      }
      if (list[i].amount == "") {
        emptyAmountFlag = true;
      }
    }
    if (emptyAmountFlag == true) {
      setAmountNotSet(true);
    } else {
      setAmountNotSet(false);
    }
    if (emptyPaymentNumberFlag == true) {
      setPaymentNumberNotSet(true);
    } else {
      setPaymentNumberNotSet(false);
    }

    setInputList(list);
    updateTotalAmount(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setAmountNotSet(true);
    setPaymentNumberNotSet(true);

    setInputList([
      ...inputList,
      { type: "invoice", proposalNumber: "", amount: "" },
    ]);
  };

  const rows = inputList.map((row) => (
    <tr>
      <td>{row.type.replace("_", " ").toUpperCase()}</td>
      <td>{row.proposalNumber}</td>
      <td>{"$" + row.amount + " " + currency.toUpperCase()}</td>
    </tr>
  ));

  const recaptchaRef = React.createRef();

  function onCaptchaChange(value) {
   
    var d = {
      key: value
    };

    setInvalidCaptcha(false);
    setRecaptchaUserToken(d);
    

    /*
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")
    {
      console.log("Localhost detected");
      setInvalidCaptcha(false);
      return;
    }
    */
    
    /*
    axios.post("/api/captcha", d)
         .then((resp) => {
          if (resp.data == false)
          {
            setInvalidCaptcha(true);
          }
          else{
            setInvalidCaptcha(false);
          }
      })
      .catch((err) => {
      });
    */
  }

  function handlePaymentSubmit(e) {
    var validCardNumber =
      document.querySelector("#ccnumber .CollectJSValid") !== null;
    var validExpiration =
      document.querySelector("#ccexp .CollectJSValid") !== null;
    var validCvv = document.querySelector("#cvv .CollectJSValid") !== null;

    e.preventDefault();

    if (
      validCardNumber == true &&
      validCvv == true &&
      validExpiration == true
    ) {
      setLoading(true);
      window.CollectJS.startPaymentRequest();
    } else {
      var statusObj = {
        state: "fail",
        message: "Be sure the card you are entering has correct information",
      };
      setPaymentStatus(statusObj);
    }
  }

  function submitPayment(token, form) {

    console.log("Token from re-captcha:", form.recaptcha_user_token);

    var data = {
      recaptcha_user_token: form.recaptcha_user_token,
      payment_token: token.token,
      first_name: form.first_name,
      last_name: form.last_name,
      company: form.company,
      address: form.address,
      city: form.city,
      zip: form.postal,
      state: form.state,
      country: form.country,
      phone: form.phone,
      email: form.email,
      currency: form.currency,
      amount: form.amount,
      orderDesc: form.orderDesc,
    };

    axios
      .post("/api/payment", data)
      .then((resp) => {
        setPaymentStatus(resp.data);

        if (resp.data.state == "success") {
          //redirect to payment complete
          history.push("/complete");
        } else if (resp.data.state == "fail") {
          console.log("Transaction Failed");
        }

        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        var s = {
          state: "fail",
          message:
            "There was an unexpected server error. Please contact RAS for payment support",
        };
        setPaymentStatus(s);
        setLoading(false);
      });
  }

  function updateTotalAmount(list) {
    var total = 0;
    for (var i = 0; i < list.length; i++) {
      total += parseFloat(list[i].amount);
    }
    if (!isNaN(total)) {

      if (total > 50000)
      {
        setTotalAmountError(true);
      }
      else {
        setTotalAmountError(false);
      }

      setTotalAmount(total);
    }
  }

  function changeAddress(address) {
    setAddressNotSet(false);

    var c = address.value.terms[address.value.terms.length - 1].value;

    if (c== "Canada") {
      setCountry("CA");
      setCurrency("CAD");
      setKey(config.nmi_public_key_cad);
    } else if (c == "USA") {
      setCountry("US");
      setCurrency("USD");
      setKey(config.nmi_public_key_usd);
    }

    try {
      setAddress(
        address.value.terms[0].value + " " + address.value.terms[1].value
      );
      setCity(address.value.terms[2].value);
      setRegion(address.value.terms[3].value);
     
    } catch (e) {
      console.log(e);
    }
  }

  function getPlaceHolder(t) {
    if (t == "invoice") return "Invoice Number";
    if (t == "sales_order") return "Sales Order Number";
    if (t == "proposal") return "Proposal/Quote Number";
    return "";
  }

  function updateCompany(e)
  {
    setCompany(e);
  }

  function setResidentialCustomer(e)
  {
    setResCustomer(e);
    if (e == true){
      errors.company = null;
      setCompany("Not Applicable");
    }
    else if (e == false){
      setCompany("");
    }
  }

  function toggleManualAddress() {
    setToggleAutoComplete(false);
  }


  function advanceStep() {
    var currentStep = step + 1;
    setStep(step + 1);
    setAddressNotSet(true);

    switch (currentStep) {
      case 1:
        var total = 0;
        var desc = "";
        for (var i = 0; i < inputList.length; i++) {
          total += parseFloat(inputList[i].amount);
          desc +=
            " " +
            inputList[i].type +
            " " +
            inputList[i].proposalNumber +
            ":$" +
            inputList[i].amount;
        }
        setTotalAmount(total);
        setOrderDesc(desc);

        setProgress(progress + 30);
        setStepText("Step 2/3: Details");
        setStepBtnText("Next Step: Confirm");
        setInstructions(
          "Fill out the form. For assistance with an unknown invoice number or processing a payment, please contact Roll-A-Shade’s accounting department at 888.245.5077 ext. 405. "
        );
        break;
      case 2:
        setProgress(progress + 30);
        setStepText("Step 3/3: Confirm");
        var chosenCurrency = "";

        var key = "";
        if (country == "US") {
          key = config.cjs_us_key;
          setCurrency("USD");
          chosenCurrency = "USD"; 
        } else if (country == "CA") {
          key = config.cjs_cad_key;
          setCurrency("CAD");
          chosenCurrency = "CAD";
        }

        var desc = orderDesc;
        if (DBA != "") desc += " " + "| DBA: " + DBA;
        setOrderDesc(desc);

        var formData = {
          recaptcha_user_token: recaptchaUserToken,
          first_name: firstName,
          last_name: lastName,
          company: company,
          address: address,
          country: country,
          city: city,
          state: region,
          postal: postal,
          phone: phone,
          email: email,
          currency: chosenCurrency,
          amount: totalAmount, //! If the user goes back to modify the amounts this field won't populate. You may need to recall the configure method every time the user clicks backtrack
          orderDesc: desc,
        };

        if (cjsFlag == false) {
          
          const script = document.createElement("script");
          script.src = "https://secure.networkmerchants.com/token/Collect.js";
          script.async = true;
          script.setAttribute("data-tokenization-key", key);
          script.setAttribute("data-variant", "inline");

          script.setAttribute(
            "data-field-ccnumber-placeholder",
            "0000 0000 0000 0000"
          );
          script.setAttribute("data-field-ccexp-placeholder", "10 / 22");
          script.setAttribute("data-field-cvv-placeholder", "123");
          /*
          script.setAttribute('data-validation-callback', "CJValidated");
          */
          var v =
            "(function(fieldName, valid, message) { if (!valid) { console.log(fieldName + ' is invalid'); }})";
          script.setAttribute("data-validation-callback", v);

          var css = JSON.stringify({
            "border-style": "solid",
            "border-color": "c7c7c7",
            "border-width": "1px",
            "border-radius": "3px",
            padding: "6px",
            "font-size": "16px",
            height: "33px",
          });

          var invalid_css = JSON.stringify({
            "background-color": "#ffd9d0",
            color: "black",
          });

          script.setAttribute("data-custom-css", css);
          script.setAttribute("data-invalid-css", invalid_css);

          document.body.appendChild(script);

          script.addEventListener("load", function () {
            resolveCollectJS(formData);
          });

          setCjsFlag(true);
        }

        break;
        default:
          break;
    }
  }

  function CJValidated(field, validated, additional) {
    console.log(field, validated);
  }

  function resolveCollectJS(formData) {

    window.CollectJS.configure({
      callback: (token) => {
        submitPayment(token, formData);
      },

      fields: {
        ccnumber: {
          placeholder: "CC Number",
          selector: "#ccnumber",
        },
        ccexp: {
          placeholder: "CC Expiration",
          selector: "#ccexp",
        },
        cvv: {
          placeholder: "CVV",
          selector: "#cvv",
        },
      },
    });
  }

  const popover = (
    <Popover id="popover-cvv">
      <Popover.Content>
      <img
                  style={{ width: 250, marginLeft: 15, marginTop: 5 }}
                  src={cvvImage}
                />
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="App">
      <div className="bills_section">
        <Container>
          {paymentStatus.state == "fail" && (
            <div className="payment_message_container_failed">
              <h3>The Payment did not go through</h3>
              <span>{`${paymentStatus.message}`} </span>
            </div>
          )}

          <Row>
            <Col>
              <h3 className="ras_progress_text"> {`${stepText}`}</h3>
            </Col>
            {step > 0 && (
              <Col>
                <Button
                  variant="light"
                  href="/"
                  style={{ float: "right", marginTop: 20 }}
                >
                  Start Over
                </Button>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <ProgressBar
                animated
                className="RAS_Progress_Bar"
                now={progress}
                label={`${progress}%`}
              />
            </Col>
          </Row>

          {step < 2 && (
            <Row style={{ marginBottom: 30 }}>
              <Col>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <h4>Instructions for this Section</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ textAlign: "left" }}>
                        <span>{`${instructions}`}</span>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          {amountError == true && (
            <div style={{ marginBottom: 25 }}>
              <span className="error_span">
                Inproper amount(s) detected. Please enter a valid dollar amount
              </span>
            </div>
          )}
          {totalAmountError == true && (
            <div style={{ marginBottom: 25 }}>
              <span className="error_span">
                Please make sure no line items exceed $50,000 USD or the total does not exceed $50,000 USD
              </span>
            </div>
          )}
          {paymentNumberError == true && (
            <div style={{ marginBottom: 25 }}>
              <span className="error_span">
                Inproper payment number(s) detected. Please enter a valid
                Proposal/Sales/Invoice Number
              </span>
            </div>
          )}

          <div
            className="payments_container"
            style={{ display: step == 0 ? "block" : "none" }}
          >
            {inputList.map((x, i) => {
              return (
                <Row style={{ marginBottom: 20, width: "100%" }} key={i}>
                  <Col xs={4}>
                    <Form.Control
                      name="type"
                      as="select"
                      custom
                      value={x.type}
                      size="lg"
                      onChange={(e) => handleSelectInputChange(e, i)}
                    >
                      <option value="proposal">Proposal/Quote</option>
                      <option value="sales_order">Sales Order</option>
                      <option value="invoice">Invoice Order</option>
                    </Form.Control>
                  </Col>

                  <Col xs={4}>
                    <Form.Control
                      name="proposalNumber"
                      placeholder={getPlaceHolder(x.type)}
                      value={x.proposalNumber}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </Col>

                  <Col xs={3}>
                    <Form.Control
                      className="ml10"
                      name="amount"
                      placeholder="Enter Amount"
                      value={x.amount}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  
                  </Col>

                  <Col xs={1}>
                      <Button
                        variant="danger"
                        className="mr10"
                        disabled={inputList.length == 1}
                        onClick={() => handleRemoveClick(i)}
                      >
                        X
                      </Button>
                    </Col>
                  
                </Row>
              );
            })}
            <Row>
              <Col>
                <Button
                  variant="primary"
                  onClick={handleAddClick}
                  disabled={inputList.length >= 10}
                  style={{ marginTop: 20, float: "left" }}
                >
                  Add Another
                </Button>
              </Col>
              <Col>
                <h3 style={{ float: "right" }}>
                  {"Total: $" + totalAmount.toFixed(2)}
                </h3>
              </Col>
            </Row>
          </div>

          {step == 0 && (
            <div>
            <Row>
              <Col>
                <Button
                  variant="success"
                  disabled={
                    amountNotSet || paymentNumberNotSet || addressNotSet
                  }
                  style={{ marginTop: 100, marginBottom: 25 }}
                  onClick={advanceStep}
                >
                  {`${stepBtnText}`}
                </Button>
              </Col>
            </Row>
            </div>
          )}

          {step == 1 && (
            <div className="shipping">
              {/* Explore adding hidden input fields onto this form for the hash value. Or
              see if you can submit the form via JS after adding the extra form elements */}

              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md={{ span: 8, offset: 2 }}>
                    <Row>
                      <Col xs={6}>
                        <span className="payment_form_text">First Name</span>
                        <Form.Control
                          name="firstName"
                          defaultValue={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          ref={register({ required: true, maxLength: 30 })}
                        />
                        <span className="payment_form_error_text">
                          {errors.firstName &&
                            "First name is required and limited to 30 characters"}
                        </span>
                      </Col>
                      <Col xs={6}>
                        <span className="payment_form_text">Last Name</span>
                        <Form.Control
                          name="lastName"
                          defaultValue={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          ref={register({ required: true, maxLength: 30 })}
                        />
                        <span className="payment_form_error_text">
                          {errors.lastName &&
                            "Last name is required and limited to 30 characters"}
                        </span>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6}>
                        <span className="payment_form_text">Email</span>
                        <Form.Control
                          name="email"
                          defaultValue={email}
                          onChange={(e) => setEmail(e.target.value)}
                          ref={register({
                            required: true,
                            maxLength: 100,
                            pattern: /^\S+@\S+$/i,
                          })}
                        />
                        <span className="payment_form_error_text">
                          {errors.email &&
                            "Valid Email is required and limited to 100 characters"}
                        </span>
                      </Col>
                      <Col xs={6}>
                        <span className="payment_form_text">Phone Number</span>
                        <Form.Control
                          name="phone"
                          defaultValue={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          ref={register({
                            required: true,
                            pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                          })}
                        />
                        <span className="payment_form_error_text">
                          {errors.phone &&
                            "Phone number must be in (XXXXXXXXXX or XXX-XXX-XXXX) format. Do not include country code"}
                        </span>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={4}>
                        <span className="payment_form_text">
                          Company
                        </span>
                        <Form.Control
                          name="company"
                          value={company}
                          disabled={resCustomer}
                          ref={register({
                            maxLength: 100,
                            required: true
                          })}
                          onChange={(e) => updateCompany(e.target.value)}
                        />
                        <span className="payment_form_error_text">
                          {errors.company && "Company required, max 100 characters"}
                        </span>
                      </Col>
                      <Col xs={4}>
                      <span style={{position: "relative", bottom: 5}}>I am a Residential Customer</span>
                      <input
                        onChange={(e) => setResidentialCustomer(e.target.checked)}
                        checked={resCustomer}
                        style={{ width: "1.5em", height: "1.5em",marginTop: 25, marginLeft: 5}}
                        name="res"
                        type="checkbox"
                      />    
                      </Col>
                      <Col xs={4}>
                        <span className="payment_form_text">
                          DBA (Optional)
                        </span>
                        <Form.Control
                          name="dba"
                          defaultValue={DBA}
                          ref={register({
                            maxLength: 100,
                          })}
                          onChange={(e) => setDBA(e.target.value)}
                        />
                      </Col>
                      <span className="payment_form_error_text">
                        {errors.dba && "DBA max 100 characters"}
                      </span>
                    </Row>

                    <Row style={{marginTop: 20}}>
                      <Col>
                        <h4 className="payment_form_header">
                          Credit Card Billing Address Details
                        </h4>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <span className="payment_form_text">Address</span>
                          </Col>
                        </Row>

                        <Row style={{ marginBottom: 20 }}>
                          <Col>
                            {toggleAutoComplete == false && (
                              <Form.Control
                                name="address_manual"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                              />
                            )}

                            {toggleAutoComplete == true && (
                              <GooglePlacesAutocomplete
                                apiKey="AIzaSyC-Q_Uv3tzp3_17-UMy1CkwAB-XY1ztyZk"
                                autocompletionRequest={{
                                  componentRestrictions: {
                                    country: ["us", "ca"],
                                  },
                                  types: ["address"],
                                }}
                                selectProps={{
                                  onChange: changeAddress,
                                }}
                              />
                            )}
                            <small
                              onClick={toggleManualAddress}
                              style={{ color: "#286090", cursor: "pointer" }}
                            >
                              Address not appearing in the list?
                            </small>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6}>
                        <span className="payment_form_text">City</span>
                        <Form.Control
                          name="city"
                          defaultValue={city}
                          onChange={(e) => setCity(e.target.value)}
                          ref={register({
                            required: true,
                          })}
                        />
                        <span className="payment_form_error_text">
                          {errors.city && "City Required"}
                        </span>
                      </Col>
                      <Col xs={6}>
                        <span className="payment_form_text">
                          Postal Code/ Zip Code
                        </span>
                        <Form.Control
                          name="postal"
                          defaultValue={postal}
                          onChange={(e) => setPostal(e.target.value)}
                          ref={register({
                            required: true,
                            maxLength: 10,
                          })}
                        />
                        <span className="payment_form_error_text">
                          {errors.postal &&
                            "Postal Code Required and max 10 characters"}
                        </span>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6}>
                        <span className="payment_form_text">Country</span>

                        <Form.Control
                          as="select"
                          name="country"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          <option value="US">US</option>
                          <option value="CA">Canada</option>
                        </Form.Control>
                      </Col>
                      <Col xs={6}>
                        <span className="payment_form_text">
                          State/Province
                        </span>
                        <Form.Control
                          as="select"
                          name="province"
                          value={region}
                          onChange={(e) => setRegion(e.target.value)}
                          ref={register({
                            required: true,
                          })}
                        >
                          <option value=""></option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="PR">Puerto Rico</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                          <option value="AB">Alberta</option>
                          <option value="BC">British Columbia</option>
                          <option value="MB">Manitoba</option>
                          <option value="NB">New Brunswick</option>
                          <option value="NL">Newfoundland and Labrador</option>
                          <option value="NS">Nova Scotia</option>
                          <option value="ON">Ontario</option>
                          <option value="PE">Prince Edward Island</option>
                          <option value="QC">Quebec</option>
                          <option value="SK">Saskatchewan</option>
                          <option value="NT">Northwest Territories</option>
                          <option value="NU">Nunavut</option>
                          <option value="YT">Yukon</option>
                        </Form.Control>
                        <span className="payment_form_error_text">
                          {errors.province && "State/Province Required"}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row style={{marginTop: 30, marginLeft: "35%"}}>
            <Col>
            
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={config.captcha_client_key}
                onChange={onCaptchaChange}
              />
            </Col>
          </Row>

                <Button
                  style={{ marginTop: 30, marginBottom: 30 }}
                  variant="success"
                  disabled={address == "" || invalidCaptcha}
                  type="submit"
                >
                  Next Step: Confirm Details
                </Button>
              </form>
            </div>
          )}

          <Container style= {{overflowY: "auto", overflowX: "hidden"}}>
          {step == 2 && (
            <div>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Order Type</th>
                    <th>Order Number</th>
                    <th>Order Amount</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
              <Row>
                <Col>
                  <h3 className="total-text">
                    Total: $
                    {totalAmount.toFixed(2) + " " + currency.toUpperCase()}
                  </h3>
                </Col>
              </Row>
            </div>
          )}

          <Container style={{ visibility: step == 2 ? "visible" : "hidden" }}>
            <form onSubmit={handlePaymentSubmit}>
              <Row>
                <Col>
                  <span className="payment_form_text">Credit Card Number</span>
                  <div id="ccnumber"></div>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <span className="payment_form_text">Credit Card Exp</span>
                  <div id="ccexp"></div>
                </Col>
                <Col xs={6}>
                  <span className="payment_form_text">
                    Card security code / CVV
                  </span>
                  <OverlayTrigger trigger="click" placement="left" overlay={popover} transition={false}>
                          <Button style={{float: "right", marginLeft: 10, paddingBottom: 0, paddingTop: 0}} variant="outline-secondary">?</Button>
                        </OverlayTrigger>
                  <div id="cvv"></div>
                </Col>
                <img
                  style={{ width: 150, marginLeft: 15, marginTop: 5, marginBottom: 15 }}
                  src={paymentOptionsImage}
                />
              </Row>

              
              {/*
              <Row>
                <Col>
                
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={config.captcha_client_key}
                    onChange={onCaptchaChange}
                  />
                </Col>
              </Row>
              */}

              <Row>
                <Col>
                  <span>
                    Please check this box to confirm you are authorized to
                    process this transaction and you agree to our{" "}
                    <a href="http://payment.portalrollashade.com/terms">
                      Terms and Conditions
                    </a>
                  </span>
                  <input
                    value={termsChecked}
                    onChange={(e) => setTermsChecked(e.target.value)}
                    style={{
                      marginLeft: 20,
                      marginTop: 5,
                      width: "1.5em",
                      height: "1.5em",
                    }}
                    name="terms"
                    type="checkbox"
                  />
                </Col>
              </Row>

              {loading == true && (
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}

              <Button
                variant="success"
                style={{ marginTop: 50, marginBottom: 100 }}
                disabled={!termsChecked || loading || invalidCaptcha}
                type="submit"
                name="submit"
                value="Confirm"
              >
                Confirm & Pay
              </Button>
            </form>
          </Container>

          
          </Container>

          
        </Container>
      </div>
    </div>
  );
}

export default Payment;
