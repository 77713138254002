import React, { Component, useState } from "react";
import {isMobile} from 'react-device-detect';
import {
  Container,
  Row,
  Col,
  ProgressBar,
  Form,
  Button,
  Card,
} from "react-bootstrap";
import MyIcon from "./logo.png";
import CompleteImg from "./review.png";

function Complete() {
  const logo = require("./logo.png");
  
  return (
    <div className="App">
      <h2>Thank you for your business!</h2>
      
      <div>
      <p>We hope you are happy with our products and the work performed. We strive to offer the best customer experience possible and are always looking for ways to improve.</p>
      <p style={{marginBottom: 20}}>If you can spare a minute to leave us a review we'd be super grateful!</p>
      <a style={{color: "black", fontSize: "2em", fontWeight: 600}} href="https://www.google.com/maps/place//data=!4m3!3m2!1s0x80dcb77503fbebf5:0x144e3511d674d41!12e1?source=g.page.m.rc&laa=merchant-web-dashboard-card">LEAVE US A REVIEW</a>
      </div>
      
      <a href="https://www.google.com/maps/place//data=!4m3!3m2!1s0x80dcb77503fbebf5:0x144e3511d674d41!12e1?source=g.page.m.rc&laa=merchant-web-dashboard-card"><img src={CompleteImg} style={{marginTop: "20px", width: 400, cursor: "pointer"}}/></a>
      <p style  ={{marginTop: "30px"}}>You will receive an email confirmation shortly. You may now exit this page</p>
      <p>For questions on this payment, please contact <u>accounting@rollashade.com</u></p>

    </div>
  );
}

export default Complete;
